import { useEffect } from "react";

const WssPage = () => {

  useEffect(() => {
    let webSocket = new WebSocket('wss://ps.uverse.co.kr:33002/?libversion=4.1.6.14&sid=30&app=&IPv6');
    // let webSocket = new WebSocket('wss://dstg-ps.uverse.co.kr:35002/?libversion=4.1.6.14&sid=30&app=&IPv6');
    
    webSocket.onmessage = function(e) { 
      console.log(e);
      webSocket.send("test");
    }
    
  }, []);


  return (
    <></>
  )
}

export default WssPage;