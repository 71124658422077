import { useEffect, useRef, useState } from "react";

const MinimapGala = () => {
  
  const minimapRef = useRef();
  const [minimapLoaded, setMinimapLoaded] = useState(false);
  const [minimapStarted, setMinimapStarted] = useState(false);

  // 화살표 이동 크기
  const moveSize = 1;

  // 미니맵 패널 크기
  const mapPanelWidth = 230;
  const mapPanelHeight = 230;

  // 맵크기
  const [mapWidth, setMapWidth] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);

  // 유니티 맵 사이즈 
  const mapSize = {x: 154, y: 0, z: 121 };
  const mapPosition = {x: -154, y: 0, z: -121 };
  const mapOffset = {x:mapPosition.x * -1, y:mapPosition.y * -1, z:mapPosition.z * -1};

  // 유니티 좌표 
  const startPos = { x: -130.1999969482422, y: -0.05999999865889549, z: -29.100000381469727 };
  // const [currentPos, setCurrentPos] = useState({x: -130.1999969482422, y: -0.05999999865889549,  z: -29.100000381469727});
  const [currentPos, setCurrentPos] = useState({x: startPos.x, y: startPos.y,  z: startPos.z});
  // const [currentPos, setCurrentPos] = useState({x:1349, y:905});
  // 플레이어 좌표
  const [playerPos, setPlayerPos] = useState({x: 0, y: 0, z: 0});
  // 맵 좌표
  const [mapPos, setMapPos] = useState({x:0, y:0, z:0});

  // 유니티 좌표 -> 맵 좌표 배율
  const ratio = mapWidth / mapSize.x;

  // 플레이어 아이콘 크기
  const playerWidth = 20;
  const playerHeight = 20;

  // 맵 패딩 사이즈
  const [mapPad, setMapPad] = useState({w:0, h:0});

  useEffect(() => {
    
    if(mapWidth === 0 || mapHeight === 0) {
      return console.log('minimap not loading');
    }

    var playerX = (mapOffset.x + currentPos.x) * ratio;
    var playerZ = (mapOffset.z + currentPos.z) * ratio;

    var mapX = 0;
    var mapY = 0;

    console.log('playerX', playerX);
    console.log('playerY', playerZ);

    if(playerX < mapPad.w) {
      // 왼쪽 테두리
      console.log('가로 - 왼쪽 테두리');
      mapX = 0;
    } else if(playerX > mapWidth - mapPad.w) {
      // 오른쪽 테두리
      console.log('가로 - 오른쪽 테두리');
      mapX = (mapPad.w * 2) - mapWidth;
      playerX = mapPad.w + (mapPad.w - ((mapWidth - playerX)));
    } else {
      // 중간
      console.log('가로 - 중간');
      
      mapX = mapPad.w - playerX;
      
      playerX = mapPad.w;
    }

    if(playerZ < mapPad.h) {
      // 아래 테두리
      console.log('세로 - 아래 테두리');
      mapY = 0;
      playerZ = mapPad.h + ((playerZ - mapPad.h) * -1);
    } else if(playerZ > mapHeight - mapPad.h) {
      // 위 테두리
      console.log('세로 - 위 테두리');
      mapY = (mapHeight - (mapPad.h * 2)) * -1;

      playerZ = (mapHeight - playerZ);
    } else {
      // 중간
      console.log('세로 - 중간');

      mapY = mapPad.h - playerZ;

      playerZ = mapPad.h;
    }

    console.log('====');
    

    setMapPos({x: mapX, y: mapY});
    setPlayerPos({x: playerX, y:0, z: playerZ});
  }, [currentPos, mapPad, mapWidth, mapHeight]);

  useEffect(() => {
    console.log('map size setting...');
    if(minimapLoaded && minimapRef.current) {
      setMapWidth(minimapRef.current.width);
      setMapHeight(minimapRef.current.heighty);
      console.log('map size set!');
      setMinimapStarted(true);
    }
  }, [minimapRef, minimapLoaded]);

  const onMinimapLoaded = () => {
    console.log('minimap laoded');
    setMinimapLoaded(true);
    
  }
  
  useEffect(() => {
    setMapPad({w:mapPanelWidth / 2, h:mapPanelHeight / 2});
  }, []); 

  return (
    <div style={{ padding: 0 }}>
      <div><h1>MINIMAP</h1></div>

      {/* 미니맵 */}
      <div style={{
        width: mapPanelWidth,
        height: mapPanelHeight,
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '6px',
        background: '#000',
        display: minimapStarted ? 'block' : 'none'
      }}>
        <img src="/resources/minimap_gala.png" ref={minimapRef} alt="map"
          style={{ position: 'absolute', left: mapPos.x, bottom: mapPos.y, opacity: 1 }} onLoad={ e => { onMinimapLoaded(); }} />
        <img src="/resources/img_map_player.svg" width="20" height="20" alt="player"
          style={{ position: 'absolute', top: (playerPos.z) - (playerHeight / 2), left: (playerPos.x) - (playerWidth / 2) }}/>
      </div>
      <div style={{
        width: mapPanelWidth,
        height: mapPanelHeight,
        position: 'relative',
        borderRadius: '6px',
        background: '#000',
        display: !minimapStarted ? 'block' : 'none'
      }}>
        <img src="/resources/ico_loading_80px.gif" width="80" height="80" alt="loading..." style={{ position: 'absolute', top: '50%', left:'50%', transform: 'translate(-50%, -50%)' }} />
      </div>

      {/* 컨트롤 패널 */}
      <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <div>mapSize : {mapSize.x} / {mapSize.z}</div>
        <div>mapPosition : {mapPosition.x} / {mapPosition.z}</div>
        <div>mapOffset : {mapOffset.x} / {mapOffset.z}</div>
        <div>mapPad : {mapPad.w} / {mapPad.h}</div>
        <div>currentPos : {currentPos.x} / {currentPos.z}</div>
        <div>playerPos : {playerPos.x} / {playerPos.z}</div>
        <div>mapPos : {mapPos.x} / {mapPos.y}</div>
      </div>
      <div style={{ position: 'absolute', top: '210px', right: '10px' }}>
        <table style={{ width: '200px' }}>
        <tbody>
        <tr>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x - moveSize, y:0, z:currentPos.z + moveSize}); }}></button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x, y:0, z:currentPos.z + moveSize}); }}>top</button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x + moveSize, y:0, z:currentPos.z + moveSize}); }}></button></td>
          </tr>
          <tr>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x - moveSize, y:0, z:currentPos.z}); }}>left</button></td>
            <td style={{ padding: 0 }}></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x + moveSize, y:0, z:currentPos.z}); }}>right</button></td>
          </tr>
          <tr>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x - moveSize, y:0, z:currentPos.z - moveSize}); }}></button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x, y:0, z:currentPos.z - moveSize}); }}>buttom</button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x + moveSize, y:0, z:currentPos.z - moveSize}); }}></button></td>
          </tr>
          </tbody>
        </table>
        <div>
          <button onClick={ e => { setCurrentPos({x:-130.1999969482422, y:-0.05999999865889549, z:-29.100000381469727}); }}>시작</button>
          <button onClick={ e => { setCurrentPos({x:-35.659019470214844, y:0.6467795372009277, z:-39.52177810668945}); }}>도서관앞</button>
          <button onClick={ e => { setCurrentPos({x:-42.11436080932617, y:0.12966878712177277, z:-78.56269073486328}); }}>홍보관앞</button>
          <button onClick={ e => { setCurrentPos({x:-42.53032302856445, y:0.04852406680583954, z:-102.06548309326172}); }}>강당앞</button>
          <button onClick={ e => { setCurrentPos({x:-84.8387451171875, y:0.015450477600097656, z:-97.36088562011719}); }}>골대1</button>
          <button onClick={ e => { setCurrentPos({x:-85.22887420654297, y:0.015450477600097656, z:-32.916141510009766}); }}>골대2</button>
        </div>
      </div>
    </div>
  )
}

export default MinimapGala;