import { useEffect, useState } from "react";

const BgmAudio = () => {

  const [campusBackgroundMusicList] = useState([
    {"bgm_num":1,"bgm_name":"Stomp Logo3","bgm_path":"/mp3/1.mp3"},
    {"bgm_num":2,"bgm_name":"Home Logo","bgm_path":"/mp3/2.mp3"},
    
    // {"bgm_num":1,"bgm_name":"리스트 파가니니 주제에 의한 대연습곡 라 캄파넬라","bgm_path":"/upload/bgm/gala/2.mp3"},
    // {"bgm_num":2,"bgm_name":"계곡의 비","bgm_path":"/upload/bgm/gala/1.mp3"},
    // {"bgm_num":3,"bgm_name":"모닥불소리","bgm_path":"/upload/bgm/gala/3.mp3"},
    // {"bgm_num":4,"bgm_name":"바흐 관현악 모음곡 3번 중 아리아","bgm_path":"/upload/bgm/gala/4.mp3"},
    // {"bgm_num":5,"bgm_name":"비발디 사계 중 봄 1악장","bgm_path":"/upload/bgm/gala/5.mp3"},
    // {"bgm_num":6,"bgm_name":"비발디 사계 중 여름 3악장","bgm_path":"/upload/bgm/gala/6.mp3"},
    // {"bgm_num":7,"bgm_name":"비발디 사계 중 겨울 1악장","bgm_path":"/upload/bgm/gala/7.mp3"},
    // {"bgm_num":8,"bgm_name":"사라사테 찌고이네르바이젠","bgm_path":"/upload/bgm/gala/8.mp3"},
    // {"bgm_num":9,"bgm_name":"쇼팽 에튀드 op.10 1번","bgm_path":"/upload/bgm/gala/9.mp3"},
    // {"bgm_num":10,"bgm_name":"쇼팽 에튀드 op.10 5번","bgm_path":"/upload/bgm/gala/10.mp3"},
    // {"bgm_num":11,"bgm_name":"슈베르트 아르페지오네 소나타","bgm_path":"/upload/bgm/gala/11.mp3"},
    // {"bgm_num":12,"bgm_name":"크라이슬러 사랑의 기쁨","bgm_path":"/upload/bgm/gala/12.mp3"},
    // {"bgm_num":13,"bgm_name":"파헬벨 캐논","bgm_path":"/upload/bgm/gala/13.mp3"}
  ]);

  const [playMode, setPlayMode] = useState('repeat');
  const [currentPlayingBgmIndex, setCurrentPlayingBgmIndex] = useState(-1);
  const [bgm, setBgm] = useState();

  useEffect(() => {
    if(bgm) {
      console.log('bgm', bgm);
      bgm.onended = () => { nextBgm(); };
      bgm.loop = playMode === 'loop';
      bgm.volume = 0.2;
      console.log('bgm play()');
      bgm.play();
    }
  }, [bgm]);

  useEffect(() => {
    if(bgm) {
      bgm.loop = playMode === 'loop';
      bgm.onended = () => { nextBgm(); };
    }
  }, [playMode]);

  const getRandom = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; 
  }

  const nextBgm = e => {
    console.log('handle audio ended');

    if(playMode === 'loop') {
      console.log('looping');
    }
    else if(playMode === 'repeat') {
      console.log('repeat next song');
      setCurrentPlayingBgmIndex((currentPlayingBgmIndex + 1) % campusBackgroundMusicList.length);
    } else {
      let idx = getRandom(0, campusBackgroundMusicList.length);
      console.log('shupple next song', idx);
      if(idx === currentPlayingBgmIndex) {
        idx = (currentPlayingBgmIndex + 1) % campusBackgroundMusicList.length;
      }
      setCurrentPlayingBgmIndex(idx);
    }
  }

  useEffect(() => {
    console.log('currentPlayingBgmIndex', currentPlayingBgmIndex);
    if(currentPlayingBgmIndex >= 0) {
      if(bgm) {
        bgm.pause();
      }
      console.log('set bgm');
      setBgm(new Audio(campusBackgroundMusicList[currentPlayingBgmIndex].bgm_path));
    } else {
      if(bgm) {
        bgm.pause();
      }
      setBgm();
    }
  }, [currentPlayingBgmIndex]);

  const playBgm = e => {
    console.log('campusBackgroundMusicList.length', campusBackgroundMusicList.length);
    if(campusBackgroundMusicList.length > 0) {
      setCurrentPlayingBgmIndex(0);
    }
  }

  useEffect(() => {
    console.log('playBgm start');
    playBgm();
  }, []);

  return (
    <ul>
      <li>
        <button onClick={ e => { setPlayMode('loop'); }}>{playMode === 'loop' ? '[V]' : ''}loop</button>
        <button onClick={ e => { setPlayMode('repeat'); }}>{playMode === 'repeat' ? '[V]' : ''}repeat all</button>
        <button onClick={ e => { setPlayMode('shupple'); }}>{playMode === 'shupple' ? '[V]' : ''}shupple</button>
      </li>
      { campusBackgroundMusicList.map((item, index) => (
        <li key={item.bgm_num}>
          {item.bgm_name} 
          <button onClick={ e => { setCurrentPlayingBgmIndex(index === currentPlayingBgmIndex ? -1 : index); }}>{index === currentPlayingBgmIndex ? '||' : '>'}</button>
        </li>
      ))}
    </ul>
  )
}

export default BgmAudio;