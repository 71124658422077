import './App.css';
import WebPage from './page/WebPage';
import AppPage from './page/AppPage';
import { Route, Routes } from 'react-router-dom';
import MainPage from './page/MainPage';
import ApiPage from './page/ApiPage';
import BgmPage from './page/BgmPage';
import BgmAudio from './page/BgmAudio';
import MinimapPage from './page/MinimapPage';
import MinimapGala from './page/MinimapGala';
import GoogleInfo from './page/GoogleInfo';
import WssPage from './page/WssPage';
import UrlifyPage from './page/UrlifyPage';
import TempTestPage from './page/TempTestPage';

function App() {
  return (
    <Routes>
      {/* <Route path="/" element={<MainPage />} /> */}
      <Route path="/" element={<TempTestPage />} />
      
      <Route path="/api" element={<ApiPage />} />

      <Route path="/bgm" element={<BgmPage />} />
      <Route path="/audio" element={<BgmAudio />} />

      <Route path="/web" element={<WebPage />} />
      <Route path="/app" element={<AppPage />} />

      <Route path="/minimap" element={<MinimapPage />} />
      <Route path="/minimap/gala" element={<MinimapGala />} />

      <Route path="/google" element={<GoogleInfo />} />

      <Route path="/wss" element={<WssPage />} />

      <Route path="/url" element={<UrlifyPage />} />
    </Routes>
  );
}

export default App;
