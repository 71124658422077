import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const ApiPage = () => {

  const [method, setMethod] = useState('get');
  const [title, setTitle] = useState('');
  const [params, setParams] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [targetSlice, setTargetSlice] = useState('campus');

  const [resultApi, setResultApi] = useState();
  const [resultSliceVariables, setResultSliceVariables] = useState();
  const [resultSliceReducers, setResultSliceReducers] = useState();
  const [resultSliceActions, setResultSliceActions] = useState();
  const [resultSaga, setResultSaga] = useState();
  const [resultWatcherSaga, setResultWatcherSaga] = useState();

  const [resultVisible, setResultVisible] = useState(false);

  const reset = () => {
    setMethod('get');
    setTitle('');
    setParams('');
    setApiUrl('');
    setResultVisible(false);
  }

  const capitalFirstChar = (char) => {
    return char.charAt(0).toUpperCase() + char.slice(1);
  }

  const makeup = () => {
    var br = '<br />';
    var tab = '&nbsp;&nbsp;';
    var result_api = '';
    var result_slice_variables = '';
    var result_slice_reducers = '';
    var result_slice_actions = '';
    var result_saga = '';
    var result_watcher_saga = '';

    // result_api
    result_api += `// ${title}${br}`;
    result_api += `export const `;

    var paramNamesIndex = 0;
    var paramNames = params ? params.split('\n').map(p => p.split('-')[0].trim()) : [];
    var myParamNames = params ? params.split('\n').map(p => p.split('-')[2].trim()) : [];
    console.log(paramNames);

    var apiUrlStrs = apiUrl.split('/').filter(u => u !== '/' && u !== '').map((u, index) => index === 0 ? u : capitalFirstChar(u));
    var apiUrls = apiUrl.split('/').filter(u => u !== '/' && u !== '');
    var apiFunctionName = apiUrlStrs.filter(s => s.lastIndexOf(':') < 0).join('');
    result_api += apiFunctionName;
    
    result_api += ` = (${myParamNames.length > 0 ? `{ ${myParamNames.join(', ')} }`: ''}) => client.${method}(\`/${apiUrls.map(s => {
      if(s.lastIndexOf(':') < 0) {
        return s;
      } else {
        return `${'${'}${myParamNames[paramNamesIndex++]}${'}'}`;
      }
    }).join('/')}\``;

    switch(method) {
      case 'get' : result_api += ');';
        break;
      case 'delete' :
        result_api += `, {${br}`;
        result_api += `${tab}data : {${br}`;
        result_api += `${paramNames.map((p, index) => 
          `${tab}${tab}"${paramNames[index]}" : ${myParamNames[index]},${br}`
        ).join('')}`;
        result_api += `${tab}}${br}`;
        result_api += '});';
        break;
      default :
        result_api += `, {${br}`;
        result_api += `${paramNames.map((p, index) => 
          `${tab}"${paramNames[index]}" : ${myParamNames[index]},${br}`
        ).join('')}`;
        result_api += '});';
        break;
    }

    // result_slice_variables
    result_slice_variables += `${tab}${apiFunctionName}Result: null,${br}`;
    result_slice_variables += `${tab}${apiFunctionName}Error: null,${br}`;

    // result_slice_reducers
    result_slice_reducers += `${apiFunctionName}(state) {${br}`;
    result_slice_reducers += `${tab}state.loading = true;${br}},${br}`;
    result_slice_reducers += `${apiFunctionName}Success(state, action) {${br}`;
    result_slice_reducers += `${tab}state.${apiFunctionName}Result = action.payload;${br}`;
    result_slice_reducers += `${tab}state.loading = false;${br}},${br}`;
    result_slice_reducers += `${apiFunctionName}Failure(state, action) {${br}`;
    result_slice_reducers += `${tab}state.${apiFunctionName}Error = action.payload;${br}`;
    result_slice_reducers += `${tab}state.loading = false;${br}},${br}`;
    
    // result_slice_actions
    result_slice_actions += `${apiFunctionName}, ${apiFunctionName}Success, ${apiFunctionName}Failure,`;

    // result_saga
    result_saga += `export function* handle${capitalFirstChar(apiFunctionName)}(${paramNames.length > 0 ? 'action' : ''}) {${br}`;
    result_saga += `${tab}try {${br}`;
    result_saga += `${tab}${tab}const response = yield call(api.${apiFunctionName}${paramNames.length > 0 ? ', action.payload' : ''});${br}`;
    result_saga += `${tab}${tab}logger.log(\`response: \${JSON.stringify(response)}\`);${br}${br}`;
    result_saga += `${tab}${tab}yield put(slice.${apiFunctionName}Success(response));${br}`
    result_saga += `${tab}} catch (error) {${br}`
    result_saga += `${tab}${tab}yield put(slice.${apiFunctionName}Failure(error));${br}`
    result_saga += `${tab}}${br}`;
    result_saga += `}`;

    // result_watcher_saga
    result_watcher_saga += `takeLatest(${targetSlice}Slice.${apiFunctionName}.type, ${targetSlice}Handlers.handle${capitalFirstChar(apiFunctionName)}),`

    setResultApi(result_api);
    setResultSliceVariables(result_slice_variables);
    setResultSliceReducers(result_slice_reducers);
    setResultSliceActions(result_slice_actions);
    setResultSaga(result_saga);
    setResultWatcherSaga(result_watcher_saga);
    setResultVisible(true);
  }

  return (
    <div>
      <div><h1>API 생성</h1></div>
      <hr />
      <div><input type="text" name="title" placeholder="ex) 캠퍼스 정보" value={title} onChange={ e => { setTitle(e.target.value); }} /></div>
      <div>
        <button className={method === 'get' ? 'selected' : ''} onClick={ e => { setMethod('get'); }}>GET</button>
        <button className={method === 'post' ? 'selected' : ''} onClick={ e => { setMethod('post'); }}>POST</button>
        <button className={method === 'put' ? 'selected' : ''} onClick={ e => { setMethod('put'); }}>PUT</button>
        <button className={method === 'delete' ? 'selected' : ''} onClick={ e => { setMethod('delete'); }}>DELETE</button>
      </div>
      <div><textarea name="params" rows="5" placeholder="ex) cmpsCode - 캠퍼스 코드 - campusCode" value={params} onChange={ e => { setParams(e.target.value); }}></textarea></div>
      <div><input type="text" name="apiUrl" placeholder="ex)/campus/info/:cmpsCode" value={apiUrl} onChange={ e => { setApiUrl(e.target.value); }} /></div>
      <div>
        <button className={targetSlice === 'user' ? 'selected' : ''} onClick={ e => { setTargetSlice('user'); }}>user</button>
        <button className={targetSlice === 'global' ? 'selected' : ''} onClick={ e => { setTargetSlice('global'); }}>global</button>
        <button className={targetSlice === 'campus' ? 'selected' : ''} onClick={ e => { setTargetSlice('campus'); }}>campus</button>
        <button className={targetSlice === 'event' ? 'selected' : ''} onClick={ e => { setTargetSlice('event'); }}>event</button>
        <button className={targetSlice === 'myroom' ? 'selected' : ''} onClick={ e => { setTargetSlice('myroom'); }}>myroom</button>
      </div>
      <hr />
      <div>
        <button className="blue" onClick={ e => { makeup()}}>Makeup</button>
        <button className="red" onClick={ e => { reset() }}>Reset</button>
      </div>
      { resultVisible && (
      <div>
        <h1>api <CopyToClipboard text={resultApi.replaceAll('<br />', '\n').replaceAll('&nbsp;', ' ')} onCopy={ e => { alert('copyed!'); }}><button className="yellow">copy</button></CopyToClipboard></h1>
        <div className="box" dangerouslySetInnerHTML={{__html:resultApi}}></div>
        <div>
          <h1>*slice</h1>

          <h2>slice state variables <CopyToClipboard text={resultSliceVariables.replaceAll('<br />', '\n').replaceAll('&nbsp;', ' ')} onCopy={ e => { alert('copyed!'); }}><button className="yellow">copy</button></CopyToClipboard></h2>
          <div className="box" dangerouslySetInnerHTML={{__html:resultSliceVariables}}></div>

          <h2>slice reducers <CopyToClipboard text={resultSliceReducers.replaceAll('<br />', '\n').replaceAll('&nbsp;', ' ')} onCopy={ e => { alert('copyed!'); }}><button className="yellow">copy</button></CopyToClipboard></h2>
          <div className="box" dangerouslySetInnerHTML={{__html:resultSliceReducers}}></div>

          <h2>slice actions <CopyToClipboard text={resultSliceActions.replaceAll('<br />', '\n').replaceAll('&nbsp;', ' ')} onCopy={ e => { alert('copyed!'); }}><button className="yellow">copy</button></CopyToClipboard></h2>
          <div className="box" dangerouslySetInnerHTML={{__html:resultSliceActions}}></div>

          <h2>saga <CopyToClipboard text={resultSaga.replaceAll('<br />', '\n').replaceAll('&nbsp;', ' ')} onCopy={ e => { alert('copyed!'); }}><button className="yellow">copy</button></CopyToClipboard></h2>
          <div className="box" dangerouslySetInnerHTML={{__html:resultSaga}}></div>

          <h2>watcher saga <CopyToClipboard text={resultWatcherSaga.replaceAll('<br />', '\n').replaceAll('&nbsp;', ' ')} onCopy={ e => { alert('copyed!'); }}><button className="yellow">copy</button></CopyToClipboard></h2>
          <div className="box" dangerouslySetInnerHTML={{__html:resultWatcherSaga}}></div>
        </div>
      </div>
      )}
      </div>
  )
}

export default ApiPage;