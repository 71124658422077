import { useEffect, useState } from "react";

const MinimapPage = () => {

  // 이동 크기
  const moveSize = 20;

  // 미니맵 패널 크기
  const mapPanelWidth = 230;
  const mapPanelHeight = 230;

  // 맵크기
  const mapWidth = 1464;
  const mapHeight = 1020;

  // 유니티 좌표
  const [currentPos, setCurrentPos] = useState({x:115, y:115});
  // const [currentPos, setCurrentPos] = useState({x:1349, y:905});
  // 플레이어 좌표
  const [playerPos, setPlayerPos] = useState({x:115, y:115});
  // 맵 좌표
  const [mapPos, setMapPos] = useState({x:0, y:0});

  // 유니티 좌표 -> 맵 좌표 배율
  const ratio = 1;

  // 플레이어 아이콘 크기
  const playerWidth = 20;
  const playerHeight = 20;

  // 맵 패딩 사이즈
  const [mapPad, setMapPad] = useState({w:0, h:0});

  useEffect(() => {
    
    var playerX = currentPos.x * ratio;
    var playerY = currentPos.y * ratio;

    var mapX = 0;
    var mapY = 0;

    console.log('playerX', playerX);
    console.log('playerY', playerY);

    if(playerX < mapPad.w) {
      // 왼쪽 테두리
      console.log('가로 - 왼쪽 테두리');
      mapX = 0;
    } else if(playerX > mapWidth - mapPad.w) {
      // 오른쪽 테두리
      console.log('가로 - 오른쪽 테두리');
      mapX = (mapPad.w * 2) - mapWidth;
      playerX = mapPad.w + (mapPad.w - ((mapWidth - playerX)));
    } else {
      // 중간
      console.log('가로 - 중간');
      
      mapX = mapPad.w - playerX;
      
      playerX = mapPad.w;
    }

    if(playerY < mapPad.h) {
      // 아래 테두리
      console.log('세로 - 아래 테두리');
      mapY = 0;
      playerY = mapPad.h + ((playerY - mapPad.h) * -1);
    } else if(playerY > mapHeight - mapPad.h) {
      // 위 테두리
      console.log('세로 - 위 테두리');
      mapY = (mapHeight - (mapPad.h * 2)) * -1;

      playerY = (mapHeight - playerY);
    } else {
      // 중간
      console.log('세로 - 중간');

      mapY = mapPad.h - playerY;

      playerY = mapPad.h;
    }

    console.log('====');
    

    setMapPos({x: mapX, y: mapY});
    setPlayerPos({x: playerX, y: playerY});
  }, [currentPos, mapPad]);

  useEffect(() => {
    setMapPad({w:mapPanelWidth / 2, h:mapPanelHeight / 2});
  }, []); 

  return (
    <div style={{ padding: 0 }}>
      <div><h1>MINIMAP</h1></div>
      <div style={{
        width: mapPanelWidth,
        height: mapPanelHeight,
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '6px'
      }}>
        <img src="/resources/img_minimap_campus_main.png" width={mapWidth} height={mapHeight} alt="map"
          style={{ position: 'absolute', left: mapPos.x, bottom: mapPos.y }} />
        <img src="/resources/img_map_player.svg" width="20" height="20" alt="player"
          style={{ position: 'absolute', top: (playerPos.y) - (playerHeight / 2), left: (playerPos.x) - (playerWidth / 2) }}/>
      </div>
      <div>
        <div>mapPad : {mapPad.w} / {mapPad.h}</div>
        <div>currentPos : {currentPos.x} / {currentPos.y}</div>
        <div>playerPos : {playerPos.x} / {playerPos.y}</div>
        <div>mapPos : {mapPos.x} / {mapPos.y}</div>
      </div>
      <div>
        <table style={{ width: '200px' }}>
        <tbody>
        <tr>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x - moveSize, y:currentPos.y + moveSize}); }}></button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x, y:currentPos.y + moveSize}); }}>top</button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x + moveSize, y:currentPos.y + moveSize}); }}></button></td>
          </tr>
          <tr>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x - moveSize, y:currentPos.y}); }}>left</button></td>
            <td style={{ padding: 0 }}></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x + moveSize, y:currentPos.y}); }}>right</button></td>
          </tr>
          <tr>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x - moveSize, y:currentPos.y - moveSize}); }}></button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x, y:currentPos.y - moveSize}); }}>buttom</button></td>
            <td style={{ padding: 0 }}><button style={{ width: '100%', height: '40px' }} onClick={ e => { setCurrentPos({x:currentPos.x + moveSize, y:currentPos.y - moveSize}); }}></button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MinimapPage;