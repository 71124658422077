import { Fragment, useEffect, useState } from "react";

const TempTestPage = () => {

  const [log, setLog] = useState('');

  const handleResize = e => {
    let data = '';
    data = data + `\ncanvas height : ${document.querySelector('#cvs').clientHeight}`;
    data = data + `\nclientHeight : ${document.documentElement.clientHeight}`;
    data = data + `\nscrollHeight : ${document.documentElement.scrollHeight}`;
    data = data + `\noffsetHeight : ${document.documentElement.offsetHeight}`;
    setLog(log + data);
  }

  useEffect(() => {
    window.addEventListener('resize touchmove', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize touchmove', handleResize);
    }
  }, []);

  return (
    <div style={{
      width: '100vw',
      height: '100%'
    }}>
      <canvas id="cvs" style={{ 
        position: 'absolute',
        background: 'green',
        width: '100%',
        height: '100%'
      }} />
      
      <div style={{
        position: 'absolute',
        width: '100%',
        top: '40px',
        background: 'gray',
        height: '50%',
        overflow: 'hidden',
        overflowY: 'auto'
      }}>
        { log.split('\n').map((d, index) => (
          <div key={index}>{d}</div>
        ))}
      </div>

      <div style={{ 
        position: 'absolute',
        background: 'yellow',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '60px',
        textAlign: 'center'
      }}>BOTTOM</div>
    </div>
  )
}

export default TempTestPage;