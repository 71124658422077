import { Component, Fragment, useState } from "react";

class Urlify extends Component {

  render() {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return this.props.children.split(' ').map((item, index) => (
      <Fragment key={index}> {urlRegex.test(item) ? <a href={item} target="_blank">{item}</a> : item}</Fragment>
    ));
  }
}

const UrlifyPage = () => {

  const [messages, setMessages] = useState([
    { msg: 'hello there'},
    { msg: 'visit here https://uverse.co.kr' },
    { msg: 'visit here too https://naver.com네이버닷컴' },
  ]);

  return (
    <div>
      <ul>
      { messages.map((item, index) => (
        <li key={index}><Urlify>{item.msg}</Urlify></li>
      ))}
      </ul>
    </div>
  )
}

export default UrlifyPage;