import { useEffect, useRef, useState } from "react";

const BgmPage = () => {

  const [bgm, setBgm] = useState();
  const [isRandomPlay, setIsRandomPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const [campusBackgroundMusicList] = useState([
    {"bgm_num":1,"bgm_name":"리스트 파가니니 주제에 의한 대연습곡 라 캄파넬라","bgm_path":"/upload/bgm/gala/2.mp3"},
    {"bgm_num":2,"bgm_name":"계곡의 비","bgm_path":"/upload/bgm/gala/1.mp3"},
    {"bgm_num":3,"bgm_name":"모닥불소리","bgm_path":"/upload/bgm/gala/3.mp3"},
    {"bgm_num":4,"bgm_name":"바흐 관현악 모음곡 3번 중 아리아","bgm_path":"/upload/bgm/gala/4.mp3"},
    {"bgm_num":5,"bgm_name":"비발디 사계 중 봄 1악장","bgm_path":"/upload/bgm/gala/5.mp3"},
    {"bgm_num":6,"bgm_name":"비발디 사계 중 여름 3악장","bgm_path":"/upload/bgm/gala/6.mp3"},
    {"bgm_num":7,"bgm_name":"비발디 사계 중 겨울 1악장","bgm_path":"/upload/bgm/gala/7.mp3"},
    {"bgm_num":8,"bgm_name":"사라사테 찌고이네르바이젠","bgm_path":"/upload/bgm/gala/8.mp3"},
    {"bgm_num":9,"bgm_name":"쇼팽 에튀드 op.10 1번","bgm_path":"/upload/bgm/gala/9.mp3"},
    {"bgm_num":10,"bgm_name":"쇼팽 에튀드 op.10 5번","bgm_path":"/upload/bgm/gala/10.mp3"},
    {"bgm_num":11,"bgm_name":"슈베르트 아르페지오네 소나타","bgm_path":"/upload/bgm/gala/11.mp3"},
    {"bgm_num":12,"bgm_name":"크라이슬러 사랑의 기쁨","bgm_path":"/upload/bgm/gala/12.mp3"},
    {"bgm_num":13,"bgm_name":"파헬벨 캐논","bgm_path":"/upload/bgm/gala/13.mp3"}
  ]);

  const [currentMp3, setCurrentMp3] = useState();

  const audioRef = useRef();

  const audioEnded = e => {
    console.log('audioEnded!');
    if(isRandomPlay) {

    } else {

    }
  }

  const play = e => {
    if(isPlaying) {
      setIsPlaying(false);
      audioRef.current.muted = true;
      audioRef.current.pause();
      audioRef.current.muted = false;
    } else {
      setIsPlaying(true);
      audioRef.current.muted = true;
      audioRef.current.play();
      audioRef.current.muted = false;
    }
  }
    
  useEffect(() => {
    // setCurrentMp3(`https://cdn.galalab.xyz${campusBackgroundMusicList[0].bgm_path}`);
    // audioRef.current.src = `https://cdn.galalab.xyz${campusBackgroundMusicList[0].bgm_path}`;
    // audioRef.current.muted = true;
    // audioRef.current.play();
    // audioRef.current.muted = false;
    play();
  }, []);


  return (
    <>
      {/* <audio ref={audioRef} onEnded={audioEnded} autoPlay muted controls></audio> */}
      <button onClick={play}>{ isPlaying ? '멈춤' : '시작' }</button>
    </>
  )
}

export default BgmPage;