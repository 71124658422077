import { useEffect, useState } from "react";
import { Link, useLinkClickHandler } from "react-router-dom";

const WebPage = () => {

  useEffect(() => {

    if (document) {
      let items = document.querySelectorAll("#dynamicStyle");
      for ( let i = 0; i < items.length; i++){
          document.head.removeChild(items[i]);
      }
      var link = document.createElement("link");
      link.id = "dynamicStyle";
      link.rel = "stylesheet";
      link.type = "text/css"; 
      link.href = "/web.css";
      
      document.head.appendChild(link);
    }

    return () => {
      let items = document.querySelectorAll("#dynamicStyle");
      for ( let i = 0; i < items.length; i++){
          document.head.removeChild(items[i]);
      }
    }
  }, []);

  return (
    <div>
      THIS IS WEB!!!
      <br />
      <br />
      <Link to="/app">APP으로 가기</Link>
    </div>
  )
}

export default WebPage;