import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const GoogleInfo = () => {

  // const apiKey = '475587800248-jk6jsg5aqssabokaqcbleh1ri69hqf0c.apps.googleusercontent.com';
  const apiKey = 'AIzaSyDCoIYJDGHPyqqs5_X_b2lPdbGQRYKgbSc';

  // 로그인 처리
  const handleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', { headers: { Authorization: `Bearer ${access_token}` } },);

      console.log('userInfo : ', userInfo);


      const personFields = "externalIds,organizations,birthdays,genders"

      // const people = await axios.get(`https://people.googleapis.com/v1/people/${userInfo.data.sub}?alt=json&personFields=${personFields}&key=${apiKey}&access_token=${access_token}`, 
      const people = await axios.get(`https://people.googleapis.com/v1/people/me?alt=json&personFields=${personFields}&key=${apiKey}&access_token=${access_token}`, 
      { headers: { Authorization: `Bearer ${access_token}` } },);

      console.log(people);

      // fetch(`https://people.googleapis.com/v1/people/${userInfo.data.sub}?alt=json&personFields=${personFields}&key=${apiKey}&access_token=${access_token}`, {
      //   method: 'GET',
      //   headers: {
      //     // 'Content-Type': 'application/json',
      //     "Authorization": `Bearer ${access_token}`,
      //     "Access-Control-Allow-Credentials": true,
      //     "Access-Control-Allow-Origin": "http://localhost:3003"
      //   },
      //   mode: 'cors',
      //   credentials: 'include'
      // })
      // .then((res) => {
      //   if(res.status === 200){
      //     return res.json();  
      //   }
      //   return console.log('status !== 200', res);
      // })
      // .then((data) => {
      //   console.log(data);
      //   return;
      // })
      // .catch((error) => {
      //   console.error(error);
      // });

      // console.log('profile', profile);

    },
  });


  return (
    <div>
      <h1>구글 정보</h1>
      <div>
        <button type="button" onClick={ e => { handleLogin(); }}>구글로 시작하기</button>
      </div>
    </div>
  )
}

export default GoogleInfo;